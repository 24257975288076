import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './styles.css';
import { sidelinkClicked } from "../sidebar/Sidebar";

export default class Home extends Component {
    componentDidMount() {
        sidelinkClicked(0);
    }
    render() {
        return (
            <div className='maindiv'>
                <div className='subdiv' style={{ marginBottom: '10px' }}>
                    <div>
                        <Link to='/uploadmap'>
                            <img className="dashboard-card" alt="" src='/images/UploadFloor.png' />
                        </Link>
                    </div>
                    <div>
                        <Link to="/zoneconfig">
                            <img className="dashboard-card" alt="" src='/images/zoneconfig.png' />
                        </Link>
                    </div>
                    <div>
                        <Link to='/configuration'>
                            <img className="dashboard-card" alt="" src='/images/Config1.png' />
                        </Link>
                    </div>
                </div>

                <div className='subdiv'>
                    <div>
                        <Link to='/thermal'>
                            <img className="dashboard-card" alt="" src='/images/Thermal.png' />
                        </Link>
                    </div>
                    <div>
                        <Link to='/health'>
                            <img className="dashboard-card" alt="" src='/images/health.png' />
                        </Link>
                    </div>
                    <div>
                        <Link to='/alerts'>
                            <img className="dashboard-card" alt="" src='/images/alets.png' />
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
