import React, { Component } from 'react'
import $ from 'jquery'
import axios from "axios";
import "./styles.css";
import { masterGateway, slaveGateway } from "../../urls/apis";
import { SessionOut } from './common';

export default class Slave extends Component {
  componentDidMount() {
    axios({
      method: "GET",
      url: masterGateway,
    })
      .then((response) => {
        $("#mastergatewayid").empty();
        if (response.status === 201 || response.status === 200) {
          let fdata = response.data;
          if (fdata.length !== 0) {
            for (let i = 0; i < fdata.length; i++) {
              $("#mastergatewayid").append(
                "<option value="+fdata[i].gatewayid+">" + fdata[i].gatewayid + "</option>"
              );
            }
          } else {
            $(".error-msg").text(
              "Please Register Master Gateway to Register Slave Gateway."
            );
          }
        } else {
          $(".error-msg").text("Unable to get Gateway ID");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          $("#displayModal").css("display", "block");
        } else if (error.response.status === 404) {
          $(".error-msg").text("No Master Gateway Registered.");
        } else {
          $(".error-msg").text(
            "Request Failed with status code (" + error.response.status + ")."
          );
        }
      });
  }

  /** Hides all error and success messages displayed on all button clicks */
  hide = () => {
    $(".error-msg").text("");
    $(".success-msg").text("");
  };

  /** Method to register slave gateway under specific master gateway */
  registerSlave = (e) => {
    this.hide();
    $("#slave-del-form").css("display", "none");
    e.preventDefault();
    // let master = $("#mastergatewayid").val();
    let slave = $("#slaveid").val();
    if (slave.length === 0) {
      $(".error-msg").text("Please Enter Slave-Gateway ID.");
    } else if (!slave.match("([A-Za-z0-9]{2}[-]){5}([A-Za-z0-9]){2}")) {
      $(".error-msg").text("Invalid Slave-Gateway ID Entered.");
    } else {
      let gateway = $("#mastergatewayid").val();

      axios({
        method: "POST",
        url: slaveGateway,
        data: { master: gateway, macaddress: slave },
      })
        .then((response) => {
          console.log("Slave Res===>", response);
          if (response.status === 200 || response.status === 201) {
            $(".success-msg").text("Slave Gateway Registered Successfully.");
            $("#slaveid").val("");
          } else if (response.status === 208) {
            $(".error-msg").text(response.data.message);
          } else {
            $(".error-msg").text("Unable to Registered Slave Gateway.");
          }
        })
        .catch((error) => {
          console.log("Slave Error===>", error);
          if (error.response.status === 403) {
            $("#displayModal").css("display", "block");
          } else {
            $(".error-msg").text(
              "Request Failed with status code (" + error.response.status + ")."
            );
          }
        });
    }
  };

  /** Method to delete already registered slave gateways */
  unregisterSlave = (e) => {
    this.hide();
    e.preventDefault();
    let id = $("#slaveid-del").val();
    if (id.length === 0) {
      $(".error-msg").text("Please Enter Slave-Gateway ID for Deletion.");
    } else if (!id.match("([A-Za-z0-9]{2}[-]){5}([A-Za-z0-9]){2}")) {
      $(".error-msg").text("Invalid Slave-Gateway ID Entered.");
    } else {
      axios({
        method: "DELETE",
        url: slaveGateway,
        data: { macaddress: id },
      })
        .then((response) => {
          console.log("Slave Res===>", response);
          if (response.status === 200 || response.status === 201) {
            $(".success-msg").text(
              "Slave Gateway Removed Successfully."
            );
            $("#slaveid-del").val("");
            this.show();
          } else {
            $(".error-msg").text("Unable to Removed Slave Gateway.");
          }
        })
        .catch((error) => {
          console.log("Slave Error===>", error);
          if (error.response.status === 403) {
            $("#displayModal").css("display", "block");
          } else {
            $(".error-msg").text(
              "Request Failed with status code (" + error.response.status + ")."
            );
          }
        });
    }
  };
  show = () => {
    document.getElementById("slave-del-form").style.display = $("#slave-del-form").css("display") === 'block' ? 'none' : 'block'
  }
  
  render() {
    return (
      <div style={{ marginLeft: '45px' }} >
        <h3 className='subheading' style={{ paddingTop: '1px' }}>Slave Registration</h3>
        <div className="underline"></div>

        <div>
          {/* Elements for displaying error messages */}
          <strong>
            <span className="error-msg" id="slave-error"></span>
          </strong>
          <strong>
            <span className="success-msg" id="slave-success"></span>
          </strong>
        </div>
        {/* Form to register slave gateway */}
        <form id="slave-reg-form">
          {/* Input field for Gateway ID */}
          <div className="input-group">
            <span className="label">Master Gateway ID :</span>
            <select id="mastergatewayid"></select>
          </div>
          {/* Select list for Floor names */}
          <div className="input-group">
            <span className="label">Gateway ID :</span>
            <input
              type="text"
              name="slaveid"
              id="slaveid"
              required="required"
              placeholder="5a-c2-15-00-00-00"
              onChange={this.hide}
            />
          </div>
        </form>
        <div style={{ display: "flex", margin: "15px", marginLeft: '135px' }}>
          <input
            style={{ background: '#fe5b1bb3', color: '#000000b0' }}
            type="submit"
            onClick={() => {
              this.show();

            }}
            value="Remove Gateway"
            className="btn success-btn"
          />
          <input style={{ marginLeft: "40px", }}
            type="submit"
            onClick={this.registerSlave}
            value="Register Gateway"
            className="btn success-btn"
          />

        </div>
        {/* Form for deleting the registered gateway tags */}
        <form
          id="slave-del-form"
          className="fading"
          style={{ display: "none", marginTop: '20px' }}
        >
          {/* Input Field for Tag MAC ID */}
          <div className="input-group">
            <span className="label">Gateway MAC ID :</span>
            <input
              type="text"
              name="slaveid-del"
              id="slaveid-del"
              required="required"
              onClick={this.hide}
              placeholder="5a-c2-15-00-00-00"
            />
          </div>
          <div className="input-group" style={{ margin: "15px", marginLeft: '195px' }}>
            <input style={{ background: '#fe5b1bb3', color: '#000000b0' }}
              type="submit"
              value="Delete Gateway"
              onClick={this.unregisterSlave}
              className="btn success-btn"
            />
          </div>
        </form>
        
        {/* SessionOut Component used here!  */}
        <SessionOut />
      </div>
    )
  }
}
