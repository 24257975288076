import React, { Component } from 'react';
import './login.css';
import $ from "jquery";
import axios from 'axios';

axios.defaults.xsrfHeaderName = "x-csrftoken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      message: "",
      error: false,
    }
  }

  submit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    let data = { username: username, password: password }
    if (username.length > 0 && password.length > 0) {
      axios({ method: 'POST', url: '/api/login', data: data })
        .then((res) => {
          console.log("Login Response====>", res);
          if (res.status === 200) {
            sessionStorage.setItem("login", "success");
            this.props.parentCallback("success");
          }
        })
        .catch((err) => {
          console.log("Login Error====>", err);
          sessionStorage.setItem("login", "failed");
          this.props.parentCallback("failed");
          if (err.response.status === 401) {
            this.setState({ error: true, message: 'Incorrect Credentials' })
          } else if (err.response.status === 500) {
            this.setState({ error: true, message: 'Internal Server Error' })
          } else {
            this.setState({ error: true, message: "Request Failed with status code (" + err.response.status + ")" })
          }
        })
    } else {
      this.setState({ error: true, message: 'Required All Fields' })
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const { username, password, message, error } = this.state;
    return (
      <div className='parent-container'>
        <div className='main_body' >
          <div style={{ margin: 'auto' }}>
            <img
              src="/images/Vacus_Logo.png" alt="logo"
              className="vacus-logo"
            /><br />
          </div>
          <span className="login-header1">
            Login to Dashboard
          </span><br />
          <p className="login-header2">
            Enter Username and Password Below
          </p>
          {error && (
            <div style={{ color: 'red', marginLeft: '85px' }}>
              <strong>{message}</strong>
            </div>
          )}

          <form onSubmit={this.submit}>
            <label htmlFor="" className="login-label">Username</label><br />
            <input
              type="text"
              id="username"
              name='username'
              className='input-field'
              placeholder='Username'
              value={username}
              onChange={this.handleChange} /> <br />

            <label htmlFor="" className="login-label">Password</label><br />
            <input
              type='password'
              id="password"
              name='password'
              className='input-field'
              placeholder='Password'
              onChange={this.handleChange}
              value={password} /><br />
            <button className='login-btn'>LOGIN</button>
          </form>
        </div>
      </div>
    );
  }
}
