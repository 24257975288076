import React, { Component } from 'react';
import axios from "axios";
import $ from "jquery";
import "./styles.css";
import ApexCharts from 'react-apexcharts';
import { sidelinkClicked } from '../sidebar/Sidebar'
import { DataLoading, floorMap, chartOption, showMessage, SessionOut } from './common';
import {
  zoneConfiguration
} from "../../urls/apis";
import {
  dailySensorData,
  weeklySensorData,
  monthlySensorData,
  tempertureSensor,
} from "../../urls/apis";

const graphBtn = {
  padding: "8px 10px",
  border: "none",
  marginRight: "15px",
  borderRadius: "4px",
  fontSize: "16px",
  cursor: "pointer",
  color: "Black",
  fontWeight: "bold",
  boxShadow: "3px 3px 5px 3px rgba(0, 0, 0, 0.25)",
};


export default class Thermal extends Component {
  fWidth = 0;
  fHeight = 0;
  floorData = [];

  constructor() {
    super();
    this.state = {
      inactive: 0,
      error: false,
      message: "",
      sensorMacId: "",
      loading: true,
      series: [],
      chartOpts: {},
      chartColor: [],
    }
  }

  componentDidMount = async () => {
    sidelinkClicked(4)
    this.chart_Option(["#ff1a1a", "#11610c"]);
    this.floor = await floorMap();
    if (this.floor.floorData.length !== 0) {
      this.floorData = this.floor.floorData;
      $("#floorBlock").css("display", "block");
      this.plotFloorMap();

    } else {
      this.setState({
        loading: false,
        error: this.floor.error,
        message: this.floor.message
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearTimeout(this.timeout);
  }

  plotFloorMap = () => {
    $("#zonesdet").empty();
    showMessage(this, false, false, false, "");
    this.setState({ inactive: 0 })
    let floorID = $("#fname").val();
    this.fimage = this.floorData[floorID];
    this.fWidth = this.fimage.width;
    this.fHeight = this.fimage.height;
    $("#tempimg").attr("src", this.fimage.image);
    $("#tempimg").attr("style", "width:auto;height:auto;");
    $("#lastupdated").css("display", "none");
    $("#temp").children("div").remove();
    $("#tempChart").remove();
    $("#temp .square").remove();
    $("#graph_opt").css("display", "none")
    $("input[type=text]").val("");
    this.timeout = setTimeout(async () => {
      $("#temp .square").remove();
      this.floorDisplay();
      this.plotSensors("first");
    }, 1 * 1000);
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.plotSensors("repeat")
    }, 15 * 1000);
  };

  floorDisplay = () => {
    this.wp = document.getElementById("temp").clientWidth;
    this.hp = document.getElementById("temp").clientHeight;
    $("#tempimg").attr(
      "style",
      "width:" + this.wp + "px;height:" + this.hp + "px;"
    );
  };

  getZones = async () => {
    $("#zonesdet").empty();
    let floorID = $("#fname").val();
    this.wp = document.getElementById("temp").clientWidth;
    this.hp = document.getElementById("temp").clientHeight;
    await axios({
      method: "GET",
      url: zoneConfiguration + "?floorid=" + this.floorData[floorID].id,
    })
      .then((response) => {
        console.log("getZones Response=====>", response);
        if (response.status === 200) {
          this.zones = response.data;
          if (response.data.length !== 0) {
            $(".zonesdet").show();
            $("#zonesdet").append("<option value=" + 0 + ">--Select Zones--</option>");
            let data = response.data;
            for (let i = 0; i < data.length; i++) {
              $("#zonesdet").append("<option value=" + data[i].zonename + ">" + data[i].zonename + "</option>");
            }
          } else {
            $(".zonesdet").show();
            $("#zonesdet").append("<option value=" + 0 + ">--Select Zones--</option>");
          }
        }
      })
      .catch((error) => {
        console.log("error===>", error);
        if (error.response.status === 403) {
          this.setState({ loading: false });
          $("#displayModal").css("display", "block");
        } else if (error.response.status === 404) {
          $(".zonesdet").show();
          $("#zonesdet").append("<option value=" + 0 + ">--Select Zones--</option>");
          showMessage(this, false, true, false, "Zones Data Not Found");
        } else {
          showMessage(this, false, true, false, "Request Failed with status code (" + error.response.status + ")");
        }
      });
  };

  plotSensors = (status) => {
    let fname = $("#fname").val();
    axios({
      method: "GET",
      url: tempertureSensor + "?floorid=" + this.floorData[fname].id,
    })
      .then((response) => {
        console.log("PlotSensors====>", response);
        let wpx = this.wp / this.fWidth;
        let hpx = this.hp / this.fHeight;
        if (response.status === 200) {
          $("#temp .square").remove();
          let data = response.data;
          this.sensor = data;
          if (data.length !== 0) {
            if (status === "first") {
              this.setState({ loading: true })
              $(".zonesdet").show();
              $("#zonesdet").append("<option value=" + 0 + ">All Zones</option>");
              $("#lastupdated").css("display", "block");
              for (let i = 0; i < data.length; i++) {
                $("#zonesdet").append("<option value=" + data[i].zone.zonename + ">" + data[i].zone.zonename + "</option>");
              }
            }
            else {
              this.setState({ loading: false });
              let zval = $("#zonesdet").val();
              let index = this.sensor.findIndex(item => item.zone.zonename === zval);
              if (index !== -1) {
                data = [data[index]];
              }
            }
            console.log("--------->", data)
            let inactiveCount = 0;
            for (let i = 0; i < data.length; i++) {
              let childDiv = document.createElement("div");
              let xaxis = 0, yaxis = 0;
              xaxis = parseInt(wpx * parseFloat(data[i].zone.x1));
              yaxis = parseInt(hpx * parseFloat(data[i].zone.y1));
              let senWidth = Math.ceil((data[i].zone.x2 - data[i].zone.x1) * wpx) - 3;
              let senHeight = Math.ceil((data[i].zone.y2 - data[i].zone.y1) * hpx) - 3;
              $(childDiv).attr("id", data[i].zone.zonename);

              let mil = new Date() - new Date(data[i].lastseen.replace("T", " ").substr(0, 19));
              if (mil <= (86400 * 1000)) {// Within 24hrs will display green
                $(childDiv).attr(
                  "title",
                  "\nMAC ID : " + data[i].macid +
                  "\nZone Name : " + data[i].zone.zonename +
                  "\nTemperature  : " + (data[i].temperature).toFixed(0) +
                  "\nHumidity : " + data[i].humidity.toFixed(0)
                );
                $(childDiv).attr("class", "square");
                $(childDiv).on("click", () => {
                  this.setState({ sensorMacId: data[i].macid });
                  this.getGraphData(dailySensorData, data[i].macid, "opt0");
                });
                if (parseFloat(data[i].temperature) < 25) {
                  var clr = 120;
                  if (parseInt(data[i].temperature) === 24) clr = 100;
                  else if (parseInt(data[i].temperature) === 23) clr = 80;
                  else if (parseInt(data[i].temperature) === 22) clr = 60;
                  else if (parseInt(data[i].temperature) === 21) clr = 40;
                  else if (parseInt(data[i].temperature) === 20) clr = 20;
                  $(childDiv).attr(
                    "style",
                    "border:0.5px solid black; background-color:rgb(0," +
                    clr +
                    ",255,0.5); position: absolute; cursor: pointer;" +
                    "left:" +
                    xaxis +
                    "px;top:" +
                    yaxis +
                    "px;width:" +
                    senWidth +
                    "px;height:" +
                    senHeight +
                    "px;"
                  );
                } else if (
                  parseFloat(data[i].temperature) >= 25 &&
                  parseFloat(data[i].temperature) <= 30
                ) {
                  clr = 240;
                  if (parseInt(data[i].temperature) === 30) clr = 240;
                  else if (parseInt(data[i].temperature) === 29) clr = 200;
                  else if (parseInt(data[i].temperature) === 28) clr = 160;
                  else if (parseInt(data[i].temperature) === 27) clr = 120;
                  else if (parseInt(data[i].temperature) === 26) clr = 80;
                  else if (parseInt(data[i].temperature) === 25) clr = 40;
                  $(childDiv).attr(
                    "style",
                    "border:0.5px solid black; background-color:rgb(0,255," +
                    clr +
                    ",0.5); position: absolute; cursor: pointer; left:" +
                    xaxis +
                    "px;top:" +
                    yaxis +
                    "px;width:" +
                    senWidth +
                    "px;height:" +
                    senHeight +
                    "px;"
                  );
                } else if (parseFloat(data[i].temperature) > 30) {
                  clr = 250;
                  if (parseInt(data[i].temperature) === 35) clr = 250;
                  else if (parseInt(data[i].temperature) === 34) clr = 200;
                  else if (parseInt(data[i].temperature) === 33) clr = 150;
                  else if (parseInt(data[i].temperature) === 32) clr = 100;
                  else if (parseInt(data[i].temperature) === 31) clr = 50;
                  $(childDiv).attr(
                    "style",
                    "border:0.5px solid black; background-color: rgb(255, " +
                    clr +
                    ", 0, 0.5); position: absolute; cursor: pointer; left:" +
                    xaxis +
                    "px;top:" +
                    yaxis +
                    "px;width:" +
                    senWidth +
                    "px;height:" +
                    senHeight +
                    "px;"
                  );
                }
              }
              else {
                inactiveCount += 1;
                $(childDiv).attr(
                  "title",
                  "\nMAC ID : " +
                  data[i].macid +
                  "\nTemperature  : " +
                  data[i].temperature.toFixed(0) +
                  "\nHumidity : " +
                  data[i].humidity.toFixed(0) +
                  "\nLastseen : " +
                  data[i].lastseen.substring(0, 19).replace("T", " ")
                );
                $(childDiv).attr("class", "square");
                $(childDiv).attr(
                  "style",
                  "border:0.5px solid black; background-color: rgba(255,0, 0,0.4);" +
                  "position: absolute;left: " +
                  xaxis +
                  "px;top:" +
                  yaxis +
                  "px;width:" +
                  senWidth +
                  "px;height:" +
                  senHeight +
                  "px;"
                );
              }
              $("#temp").append(childDiv);
            }
            this.setState({ inactive: inactiveCount, loading: false });
            $("#total").text(data.length);
            $("#timing").text(data[0].lastseen.substring(0, 19).replace("T", " "));
          } else {
            this.setState({ loading: false });
            $("#total").text("0");
            showMessage(this, false, true, false, "Sensor Data Not Found");
          }
        } else {
          this.setState({ loading: false });
          $("#total").text("0");
          showMessage(this, false, true, false, "Unable To Get Tags Data");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        $("#total").text("0");
        if (error.response.status === 403) {
          $("#displayModal").css("display", "block");
        } else if (error.response.status === 404) {
          showMessage(this, false, true, false, "Sensor Data Not Found");
        } else {
          showMessage(this, false, true, false, "Request Failed");
        }
      });
  };

  chart_Option = async (graphColor) => {
    this.setState({ chartColor: graphColor });
    let value = await chartOption(graphColor, "yyyy-MM-dd HH:mm:ss");
    this.setState({ chartOpts: value });
  }

  getGraphData = (apiurl, id, btnOpt) => {
    this.optionChange(btnOpt);
    showMessage(this, false, false, false, "");
    this.setState({ series: [] })
    $("#graph_opt").css("display", "block");
    $("#chartID").text(id);
    this.tagID = id;
    axios({
      method: "POST",
      url: apiurl + id,
    })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          console.log("graph====>", response)
          if (data.length !== 0) {
            let tempData = [], humidData = [];
            for (let i = 0; i < data.length; i++) {
              let time = data[i].timestamp.substring(0, 19);
              let date = new Date(time);
              let ms = date.getTime();
              tempData.push([ms, data[i].temperature]);
              humidData.push([ms, data[i].humidity]);
            }
            this.setState({
              series: [
                { name: 'Temperature(°C) ', data: tempData },
                { name: 'Humidity(RH) ', data: humidData }
              ]
            });
            this.setState({ loading: false });
            $("html, body").animate({
              scrollTop: $(
                'html, body').get(0).scrollHeight
            }, 1000);
          } else {
            this.setState({ loading: false })
            window.scrollTo(0, 0);
            showMessage(this, false, true, false, "Graph Data Not Found");
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          $("#displayModal").css("display", "block");
        } else if (error.response.status === 404) {
          this.setState({ loading: false });
          showMessage(this, false, true, false, "Graph Data Not Found");
          window.scrollTo(0, 0);
        } else {
          this.setState({ loading: false })
          showMessage(this, false, true, false, "Request Failed");
        }
      });
  };

  optionChange = (btnId) => {
    this.setState({ loading: true })
    $("#opt0").css({ background: "none", color: "#000" });
    $("#opt1").css({ background: "none", color: "#000" });
    $("#opt2").css({ background: "none", color: "#000" });
    $("#" + btnId).css({ background: "rgb(254 91 27 / 84%)", color: "#FFF" });
  };

  sessionTimeout = () => {
    $("#displayModal").css("display", "none");
    sessionStorage.removeItem('login')
    window.location.pathname = '/login'
  };

  zonedet = () => {
    clearInterval(this.interval);
    $("#graph_opt").css("display", "none");
    showMessage(this, false, false, false, "");
    this.plotSensors("repeat")
    this.interval = setInterval(() => {
      this.plotSensors("repeat")
    }, 15 * 1000);
  }

  render() {
    const { inactive, error, message, chartOpts,
      sensorMacId, loading, series } = this.state;
    return (
      <div className='maindiv'>
        <div className="panel" style={{
          overflow: loading === true ? "hidden" : "visible",
          height: loading === true ? "500px" : "auto",
        }}>
          <h1>Thermal Environment</h1>
          <div className='underline'>   </div>

          <div className="container fading" style={{ marginTop: "50px" }}>
            <div style={{
              display: "flex", justifyContent: "space-between",
              alignItems: "center", width: '94%'
            }}>
              <div className="input-group">
                <span className="label">Floor Name : </span>
                <select
                  name="type"
                  id="fname"
                  onChange={() => {
                    this.plotFloorMap();
                  }}>
                </select>
              </div>
              <div className="input-group zonesdet">
                <span className="label"
                  style={{ marginLeft: "40px", width: "150px" }}>Zones : </span>
                <select
                  name="type"
                  id="zonesdet"
                  onChange={() => this.zonedet()}
                >
                </select>
              </div>

            </div>
            <div style={{ marginRight: "40px" }}>
              <div style={{
                display: 'flex', width: '99%',
                justifyContent: "space-between",
                marginTop: '10px', marginBottom: '0px'
              }}>
                <div className="row total-sensor" style={{ marginTop: '8px' }}>
                  Total Sensors :{" "}
                  <u>
                    <span id="total" >0</span>
                  </u>
                </div>
                <div style={{ marginTop: '2px' }}>
                  <span
                    style={{ width: "auto", fontSize: "17px", color: "#484848" }}
                    className="label sub-heading"
                    id="lastupdated">
                    <strong> Last Updated : </strong>
                    <span id="timing">00:00:00</span>{" "}
                  </span>
                </div>
              </div>
            </div>
            <br />
            {error && (
              <div className="error-msg">
                <strong>{message}</strong>
              </div>
            )}
            <div id="floorBlock" style={{ display: "none", marginTop: '-5px' }}>
              <div className="row">
                <hr></hr>
              </div>
              <div className="row sub-heading" style={{ color: "black" }}>
                <div className="row sub-heading range-block">
                  <div
                    className="square"
                    style={{
                      backgroundColor: "blue",
                      display: "inline-block",
                      marginRight: "10px",
                    }}
                  ></div>
                  Cold
                  <div style={{ display: "inline" }}> ( &lt;25&deg;C )</div>
                  <div
                    className="square"
                    style={{
                      backgroundColor: "green",
                      display: "inline-block",
                      marginRight: "10px",
                      marginLeft: "20px",
                    }}
                  ></div>
                  Optimum
                  <div style={{ display: "inline" }}>
                    {" "}
                    ( 25&deg;C - 30&deg;C )
                  </div>
                  <div
                    className="square"
                    style={{
                      backgroundColor: "orange",
                      display: "inline-block",
                      marginRight: "10px",
                      marginLeft: "20px",
                    }}
                  ></div>
                  Warm
                  <div style={{ display: "inline" }}> ( &gt;30&deg;C )</div>

                  <div
                    className="square"
                    style={{
                      backgroundColor: "rgba(255,0, 0,0.7)",
                      display: "inline-block",
                      marginRight: "10px",
                      marginLeft: "20px",
                    }}
                  ></div>Inactive ({inactive})
                </div>
              </div>
              <div
                id="temp"
                style={{
                  display: "block",
                  position: "relative",
                  width: "fit-content",
                }}>
                <img id="tempimg" alt=""></img>
              </div>

              <div id="graph_opt" style={{ display: "none", marginBottom: "15px", marginLeft: "20px" }}>
                <hr></hr>
                <div className="sub-heading" style={{ textDecoration: "none" }}>
                  Thermal Map for Sensor ID : <span id="chartID"></span>
                </div>
                <br></br>
                <button
                  id="opt0"
                  className="heading"
                  style={graphBtn}
                  onClick={() => this.getGraphData(dailySensorData, sensorMacId, "opt0")}
                >
                  Daily Log
                </button>
                <button
                  id="opt1"
                  className="heading"
                  style={graphBtn}
                  onClick={() => this.getGraphData(weeklySensorData, sensorMacId, "opt1")}
                >
                  Weekly Log
                </button>
                <button
                  id="opt2"
                  className="heading"
                  style={graphBtn}
                  onClick={() => this.getGraphData(monthlySensorData, sensorMacId, "opt2")}
                >
                  Monthly Log
                </button>

                {series.length > 0 ? (
                  <div style={{ width: "95%" }}>
                    <div
                      id="chart-timeline">
                      <ApexCharts
                        options={chartOpts}
                        series={series}
                        type="area"
                        height={380} />
                    </div>
                  </div>
                ) : null}
              </div>
              <br></br>
            </div>
          </div>
          {loading === true && (
            <div
              style={{
                top: "0%",
                left: "0%",
              }} className="frame">
              <DataLoading />
            </div>
          )}
        </div>

        {/* SessionOut Component used here!  */}
        <SessionOut />
      </div>
    )
  }
}