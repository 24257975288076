import React, { Component } from 'react'
import { floorMap } from "../../urls/apis";
import axios from "axios";
import $ from "jquery";
import { sidelinkClicked } from "../sidebar/Sidebar";
import { SessionOut } from './common';

axios.defaults.xsrfHeaderName = "x-csrftoken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

export default class Uploadmap extends Component {
  constructor() {
    super();
    this.state = {
      fname: "",
      width: "",
      height: "",
      image: null,
    };
  }

  componentDidMount() {
    sidelinkClicked(1);
  }

  handleChanges = (e) => {
    $("#upload-error").text("");
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleImageChange = (e) => {
    $("#upload-error").text("");
    this.setState({
      image: e.target.files[0],
    });
  };

  submit = (e) => {
    $("#upload-error").text("");
    $("#upload-success").text("");
    if (
      this.state.image !== null &&
      this.state.name !== "" &&
      this.state.width !== "" &&
      this.state.height !== ""
    ) {
      e.preventDefault();
      let form_data = new FormData();
      form_data.append("name", this.state.fname);
      form_data.append("image", this.state.image, this.state.image.name);
      form_data.append("width", parseFloat(this.state.width));
      form_data.append("height", parseFloat(this.state.height));
      axios({
        method: "POST",
        url: floorMap,
        headers: {
          "content-type": "multipart/form-data",
        },
        data: form_data,
      })
        .then((response) => {
          console.log("Floor Reg====>", response);
          if (response.status === 200 || response.status === 201) {
            $("#upload-success").text("Floor Map Uploaded Successfully.");
          } else if (response.status === 208) {
            $("#upload-error").text(response.data.message);
          } else {
            $("#upload-error").text("Unable to Upload Floor Map Image.");
          }
        })
        .catch((error) => {
          console.log("Floor error====>", error);
          if (error.response.status === 403) {
            $("#upload_displayModal").css("display", "block");
            $("#content").text(
              "User Session has Timed Out. Please Login Again"
            );
          } else {
            $("#upload-error").text(
              "Request Failed with status code (" + error.response.status + ")."
            );
          }
        });
    } else {
      $("#upload-error").text("Please Enter All Fields.");
    }
    $("input[type=text]").val("");
    $("input[type=file]").val("");
    $("input[type=number]").val("");
  };

  // delete = () => {
  //   axios({ method: "DELETE", url: "/api/uploadmap", data: { id: "48" } })
  //     .then((res) => {
  //       console.log("Floor Delete==========>", res);
  //     })
  //     .catch((error) => {
  //       console.log("Floor====>", error);
  //     });
  // };

  sessionTimeout = () => {
    $("#upload_displayModal").css("display", "none");
    sessionStorage.setItem("isLoggedIn", 0);
    this.props.handleLogin(0);
  };

  render() {
    const { height } = this.state;
    return (
      <div className='maindiv'>
        <div className="panel">
          <h1>Upload Floor Map</h1>
          <div className='underline'>
          </div>

          <div className="container fading" style={{ marginTop: "50px" }}>
            <strong>
              <p style={{ textAlign: "start", textTransform: 'capitalize' }} className="error-msg" id="upload-error"></p>
            </strong>
            <strong>
              <p style={{ textAlign: "start", textTransform: 'capitalize' }} className="success-msg" id="upload-success"></p>
            </strong>
            <div className="row">
              <form id="uploadForm">
                <div className="input-group">
                  <span className="label">Floor Name : </span>
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    // value={fname}
                    required="required"
                    onChange={this.handleChanges}
                  />
                </div>
                {/* Input field for Floor Map Image */}
                <div className="input-group">
                  <span className="label">Floor Map : </span>
                  <input
                    type="file"
                    name="floorimg"
                    id="floorimg"
                    accept="image/*"
                    required="required"
                    onChange={this.handleImageChange}
                  />
                </div>
                {/* Input field Floor width */}
                <div className="input-group">
                  <span className="label">Floor Width (in m) : </span>
                  <input
                    type="number"
                    name="width"
                    id="width"
                    // value={width}
                    required="required"
                    min="0"
                    placeholder="Width in meter"
                    onChange={this.handleChanges}
                  />
                </div>
                {/* Input field Floor height */}
                <div className="input-group">
                  <span className="label">Floor Height (in m) :</span>
                  <input
                    type="number"
                    name="height"
                    id="height"
                    value={height}
                    required="required"
                    min="0"
                    placeholder="Height in meter"
                    onChange={this.handleChanges}
                  />
                </div>
                {/* Button for uploading floor map */}
                <div className="input-group"
                  style={{ marginTop: '20px', marginLeft: "50px" }}>
                  <span className="label"></span>
                  <input
                    type="submit"
                    value="Upload Map"
                    id="reg"
                    onClick={this.submit}
                    className="btn success-btn"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>


        {/* SessionOut Component used here!  */}
        <SessionOut />
      </div>
    )
  }
}
