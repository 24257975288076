import { React, Component } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from "./components/login/Login";
import Sidebar from './components/sidebar/Sidebar';
import Home from './components/pages/Home';
import Health from './components/pages/Health'
import Uploadmap from './components/pages/Uploadmap.jsx'
import Zoneconfig from './components/pages/Zoneconfig'
import Configuration from './components/pages/Configuration'
import Alerts from './components/pages/Alerts';
import Thermal from './components/pages/Thermal';

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      loggedin: false,
      status: 'failed'
    }
  }
  login = () => {
    let data = sessionStorage.getItem('login')
    this.setState({ status: data });
  }
  componentDidMount() {
    let data = sessionStorage.getItem('login')
    this.setState({ status: data });
  }
  render() {
    const { status } = this.state;
    if (status === "failed" || status === null) {
      return (
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route exact path="/login" element={<Login parentCallback={this.login} />} />
          </Routes>
        </Router>
      )
    }

    return (
      <>
        <Router>
          <Sidebar />
          <Routes>
            <Route path="/login" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route exact path="/alerts" element={<Alerts />} />
            <Route exact path="/health" element={<Health />} />
            <Route exact path="/uploadmap" element={<Uploadmap />} />
            <Route exact path="/zoneconfig" element={<Zoneconfig />} />
            <Route exact path="/thermal" element={<Thermal />} />
            <Route exact path="/configuration" element={<Configuration />} />
          </Routes>
        </Router>
      </>
    );
  }

}
