import React, { Component } from 'react'
import axios from "axios";
import "./styles.css";
import $ from "jquery";
import { SessionOut, showMessage } from './common';
import { sidelinkClicked } from "../sidebar/Sidebar";
import { floorMap, zoneConfiguration } from "../../urls/apis";

export default class Zoneconfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      floorWidth: 0,
      floorHeight: 0,
    };
  }

  componentDidMount = async () => {
    sidelinkClicked(2)
    $("#cursordiv").hide();
    await this.getFloorDetail();
    this.timeout = setTimeout(async () => {
      await this.floorDisplay();
      this.plotSensors();
    }, 1500)
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  getFloorDetail = async () => {
    await axios({
      method: "GET",
      url: floorMap,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          console.log("Floor ========>", response)
          this.fdata = response.data;
          this.fimage = this.fdata[0];
          this.fWidth = this.fimage.width;
          this.fHeight = this.fimage.height;
          $("#tempimg").attr("src", this.fimage.image);
          $("#tempimg").attr("style", "width:auto;height:auto;");
          if (this.fdata.length !== 0) {
            for (let i = 0; i < this.fdata.length; i++) {
              $("#fname").append(
                "<option value=" +
                this.fdata[i].id +
                ">" +
                this.fdata[i].name +
                "</option>"
              );
              $("#fname1").append(
                "<option value=" +
                this.fdata[i].id +
                ">" +
                this.fdata[i].name +
                "</option>"
              );
            }
            this.setState({ floorWidth: this.fWidth, floorHeight: this.fHeight });
          } else {
            $(".error-msg").text(
              "Please Upload Floormap ."
            );
          }
        } else {
          $(".error-msg").text("Unable To Fetch Floor Names");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          $("#displayModal").css("display", "block");
        } else {
          $(".error-msg").text(
            "Request Failed with status code (" + error.response.status + ")."
          );
        }
      });
  }

  floorDisplay = async () => {
    this.wp = document.getElementById("temp").clientWidth;
    this.hp = document.getElementById("temp").clientHeight;
    $("#tempimg").attr(
      "style",
      "width:" + this.wp + "px;height:" + this.hp + "px;"
    );
  };

  plotSensors = () => {
    let floorId = $("#fname").val();
    axios({
      method: "GET",
      url: "/api/zones?floorid=" + floorId,
    })
      .then((response) => {
        console.log("PlotSensors====>", response);
        let wpx = this.wp / this.fWidth;
        let hpx = this.hp / this.fHeight;
        if (response.status === 200) {
          $("#temp .square").remove();
          let data = response.data;
          if (data.length !== 0) {
            let inactiveCount = 0;
            for (let i = 0; i < data.length; i++) {
              let childDiv = document.createElement("div");
              let xaxis = 0, yaxis = 0;
              xaxis = parseInt(wpx * parseFloat(data[i].x1));
              yaxis = parseInt(hpx * parseFloat(data[i].y1));
              let senWidth = Math.ceil((data[i].x2 - data[i].x1) * wpx) - 3;
              let senHeight = Math.ceil((data[i].y2 - data[i].y1) * hpx) - 3;
              $(childDiv).attr("id", data[i].zonename);
              $(childDiv).attr(
                "title",
                "\nZone Name : " + data[i].zonename
              );
              $(childDiv).attr("class", "square");
              $(childDiv).attr(
                "style",
                "border:0.5px solid black; background-color:#97979780;" +
                "position: absolute; cursor: pointer;" +
                "left:" +
                xaxis +
                "px;top:" +
                yaxis +
                "px;width:" +
                senWidth +
                "px;height:" +
                senHeight +
                "px;"
              );
              $("#temp").append(childDiv);
            }
            this.setState({ inactive: inactiveCount, loading: false });
            this.coOrdinateBlock("yes");
          } else {
            this.setState({ loading: false });
            showMessage(this, false, true, false, "Sensor Data Not Found");
          }
        } else {
          this.setState({ loading: false });
          showMessage(this, false, true, false, "Unable To Get Tags Data");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response.status === 403) {
          $("#displayModal").css("display", "block");
          $("#content").text("User Session has timed out. Please Login again");
        } else if (error.response.status === 404) {
          showMessage(this, false, true, false, "Sensor Data Not Found");
        } else {
          showMessage(this, false, true, false, "Request Failed");
        }
      });
  };

  show = () => {
    $("input[type=text]").val("");
    $("input[type=number]").val("");
    document.getElementById("del_form").style.display =
      $("#del_form").css("display") === "none" ? "block" : "none";
    window.scrollTo(0, document.body.scrollHeight);
  };

  hide = () => {
    $(".error-msg").text("");
    $(".success-msg").text("");
  };

  register = (e) => {
    e.preventDefault();
    this.hide();
    $("#del_form").css("display", "none");
    let floor = $("#fname").val();
    let zonename = $("#zonename").val();
    let x1 = $("#x").val();
    let y1 = $("#y").val();
    let x2 = $("#x1").val();
    let y2 = $("#y1").val();
    if (
      zonename.length !== 0 &&
      x1.length !== 0 &&
      y1.length !== 0 &&
      x2.length !== 0 &&
      y2.length !== 0
    ) {
      let data = {
        id: floor,
        zonename: zonename,
        x1: x1,
        y1: y1,
        x2: x2,
        y2: y2,
      };
      console.log(data);
      axios({ method: "POST", url: zoneConfiguration, data: data })
        .then((response) => {
          console.log("Register Response===>", response);
          if (response.status === 201) {
            $("#leftval").val("");
            $("#topval").val("");
            $("#zonename").val("");
            $("#x").val("");
            $("#y").val("");
            $("#x1").val("");
            $("#y1").val("");
            $(".success-msg").text("Zone is registered successfully.");
            $('html, body').animate({ scrollTop: '0px' }, 500);
            this.plotSensors();
          } else {
            $(".error-msg").text("Unable to register zone.");
            $('html, body').animate({ scrollTop: '0px' }, 500);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            $("#displayModal").css("display", "block");
            $("#content").text(
              "User Session has timed out. Please Login again."
            );
          } else {
            $(".error-msg").text(
              "Request Failed with status code (" + error.response.status + ")."
            );
            $('html, body').animate({ scrollTop: '0px' }, 500);
          }
        });
    } else {
      $(".error-msg").text("Please Enter All Fields.");
      $('html, body').animate({ scrollTop: '0px' }, 500);
    }
  };

  unregister = (e) => {
    e.preventDefault();
    this.hide();
    let floorid = $("#fname1").val();
    let name = $("#zonename1").val();
    if (floorid.length !== 0 && name.length !== 0) {
      axios({
        method: "DELETE",
        url: zoneConfiguration + "?floorid=" + $("#fname1").val(),
        data: { floorid: floorid, zonename: name },
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            $(".success-msg").text("Zone is deleted successfully.");
            $("#del_form").css("display", "none");
            $('html, body').animate({ scrollTop: '0px' }, 500);
            this.plotSensors();
          } else {
            $(".error-msg").text("Unable To Delete Zone.");
            $('html, body').animate({ scrollTop: '0px' }, 500);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            $("#displayModal").css("display", "block");
          } else {
            $(".error-msg").text(
              "Request Failed with status code (" + error.response.status + ")."
            );
            $('html, body').animate({ scrollTop: '0px' }, 500);
          }
        });
    } else {
      $(".error-msg").text("Please Enter Zonename");
      $('html, body').animate({ scrollTop: '0px' }, 500);
    }
  };

  getPosition = (e) => {
    const { floorWidth, floorHeight } = this.state;
    $("#cursordiv").show()
    var offset = $("#tempimg").offset();
    this.left = e.pageX - offset.left;
    this.top = e.pageY - offset.top;
    this.wp = document.getElementById("tempimg").clientWidth;
    this.hp = document.getElementById("tempimg").clientHeight;
    let oneMeterWidth = this.wp / floorWidth, oneMeterHeight = this.hp / floorHeight;
    let leftx = (this.left / oneMeterWidth).toFixed(3)
    let topy = (this.top / oneMeterHeight).toFixed(3)
    if (this.left > parseInt(this.wp / 2) && this.top > parseInt(this.hp / 2) ||
      this.left > parseInt(this.wp / 2) || this.top > parseInt(this.hp / 2)) {
      $("#cursordiv").css("top", parseInt(this.top - 80) + "px")
      $("#cursordiv").css("left", parseInt(this.left - 130) + "px")
    } else {
      $("#cursordiv").css("top", parseInt(this.top) + "px")
      $("#cursordiv").css("left", parseInt(this.left) + "px")
    }
    let cursor = "X(m) : " + leftx + " \Y(m) : " + topy;
    document.getElementById("cursordiv").innerHTML = cursor
    this.leftaxis = leftx
    this.topaxis = topy
  }

  stopTracking = () => {
    document.getElementById("cursordiv").innerHTML = "";
    $("#cursordiv").hide()
  }

  fillData = () => {
    $(".alert").css("display", "block");
    $("#leftval").text("X : " + this.leftaxis)
    $("#topval").text("Y : " + this.topaxis)

    var divCount = $('div.coordinate-dots').length;
    if (divCount < 2) {
      let div = document.createElement("div");
      $(div).addClass("coordinate-dots");
      $(div).css({ "left": this.left.toFixed(3) - 5 + "px", "top": this.top.toFixed(3) - 5 + "px" });
      $(div).attr("title", `(${this.leftaxis}, ${this.topaxis})`)
      $("#temp").append(div);
      if (divCount === 0) {
        $("#x").val(this.leftaxis);
        $("#y").val(this.topaxis);
      } else if (divCount === 1) {
        $("#x1").val(this.leftaxis);
        $("#y1").val(this.topaxis);
      }
    }
  }

  coOrdinateBlock = (status) => {
    if (status === "yes") {
      $("#x").val("");
      $("#y").val("");
      $("#x1").val("");
      $("#y1").val("");
      $(".coordinate-dots").remove();
    }
    $(".alert").css("display", "none")
  }

  changeFloor = () => {
    let fid = $("#fname").val();
    for (let i = 0; i < this.fdata.length; i++) {
      if (fid === (this.fdata[i].id).toString()) {
        this.setState({ floorWidth: this.fdata[i].width, floorHeight: this.fdata[i].height });
        break;
      }
    }
  }

  render() {
    return (
      <div className='maindiv'>
        <div className="panel">
          <h1>Zone Configuration</h1>
          <div className='underline'>   </div>
          <div className="container fading" style={{ marginTop: "50px" }}></div>
          <div>
            {/* Element for displaying error messages */}
            <span className="error-msg" id="conf-error" style={{ fontWeight: 600 }}></span>
            <span className="success-msg" id="conf-success" style={{ fontWeight: 600 }}></span>
          </div>
          <form>
            <div className="input-group">
              <span className="label">Floor Name : </span>
              <select name="type" id="fname" onChange={() => this.changeFloor()}></select>
            </div>
            <div className="input-group">
              <span className="label">Zone Name : </span>
              <input type="text" id="zonename" required="required" />
            </div>
            <div className="input-group">
              <span className="label">X Co-ordinate : </span>
              <input type="number" id="x" required="required" />
            </div>
            <div className="input-group">
              <span className="label">Y Co-ordinate : </span>
              <input type="number" id="y" required="required" />
            </div>
            <div className="input-group">
              <span className="label">X1 Co-ordinate : </span>
              <input type="number" id="x1" required="required" />
            </div>
            <div className="input-group">
              <span className="label">Y1 Co-ordinate : </span>
              <input type="number" id="y1" required="required" />
            </div>

            <div className="input-group" style={{ marginTop: '20px', display: "flex" }}>
              <input type='submit'
                style={{ marginLeft: '160px', background: '#fe5b1bb3', color: '#000000b0' }}
                onClick={() => {
                  this.show();
                  this.hide();
                }}
                value="Delete Zone"
                className="btn success-btn" />
              <input style={{ marginLeft: '20px' }}
                type="submit"
                value="Add  Zone"
                onClick={this.register}
                className="btn success-btn"
              />
            </div>
          </form>

          {/* Button for toggeling for Deleting zone Form */}

          <form id="del_form" style={{ display: "none", marginTop: '20px' }} className="fading">
            <div className="input-group">
              <span className="label">Floor Name : </span>
              <select name="type" id="fname1"></select>
            </div>
            <div className="input-group">
              <span className="label">Zone Name : </span>
              <input type="text" id="zonename1" required="required" />
            </div>
            <div className="input-group">
              <input style={{ marginLeft: '200px', background: '#fe5b1bb3', color: '#000000b0', marginTop: '20px' }}
                type="submit"
                value="Delete Zone"
                onClick={this.unregister}
                className="btn success-btn"
              />
            </div>
          </form>

          <div className='alert alert-danger' style={{ display: "none" }}>
            <span>Do you want to clear the X, Y co-ordinates </span>
            <span className="alert-no"
              onClick={() => this.coOrdinateBlock("no")}>No</span>
            <span className="alert-yes"
              onClick={() => this.coOrdinateBlock("yes")}>Yes</span>
          </div>

          <div id="temp"
            style={{
              display: "block",
              position: "relative",
              width: "fit-content",
            }}>
            <img id="tempimg" alt="" onMouseMove={(e) => this.getPosition(e)}
              onMouseOut={() => this.stopTracking()} onClick={this.fillData} ></img>
            <p id="cursordiv"></p>
          </div>
        </div>

        {/* SessionOut Component used here!  */}
        <SessionOut />
      </div>

    )
  }
}

