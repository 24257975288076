import React, { Component } from 'react'
import $ from 'jquery'
import Assetreg from './Assetreg'
import Master from './Master'
import Slave from './Slave'
import { sidelinkClicked } from '../sidebar/Sidebar'

export default class Configuration extends Component {
  List = [false, false, false];
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    sidelinkClicked(3)
    this.setState({ flag: true })
    this.List[0] = true;
    $("#opt0").css({ "background": "#fe5b1bb3", "color": "white" });
  }

  optionChange = (e) => {
    $("#opt0").css({ "background": "none", "color": "#000" });
    $("#opt1").css({ "background": "none", "color": "#000" });
    $("#opt2").css({ "background": "none", "color": "#000" });
    this.setState({ flag: true })
    this.List = [false, false, false]
    let id = parseInt(e.target.id.substring(3))
    $("#" + e.target.id).css({ "background": "#fe5b1bb3", "color": "white" });
    this.List[id] = true;
  }
  render() {
    return (
      <div
        className='maindiv'>
        <h1>Registration</h1>
        <div className='underline'></div>
        <div className="container fading"
          style={{
            marginTop: "15px", border: "1px solid #fe5b1bb3", width: "980px"
          }}>
          <div className="row"
            onClick={this.optionChange}>
            <button
              id="opt0"
              className='navbtn'
            >
              Sensor Registration
            </button>
            <button
              id="opt1"
              className='navbtn'
            >
              Master Registration
            </button>
            <button
              id="opt2"
              className='navbtn'
            >
              Slave Registration
            </button>
          </div>
          <div
            className="container"
            id="childComponent"
          >
            {this.List[0] && (<Assetreg />)}
            {this.List[1] && (<Master />)}
            {this.List[2] && (<Slave />)}

          </div>
        </div>
      </div>
    )
  }
}
