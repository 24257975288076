import React, { Component } from 'react'
import $ from 'jquery'
import axios from 'axios';
import {
  floorMap,
  tempertureSensor,
} from "../../urls/apis";
import { SessionOut } from './common';

export default class Assetreg extends Component {
  componentDidMount = () => {
    this.getfloordata();
  };

  getfloordata = async () => {
    await axios({
      method: "GET",
      url: floorMap,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.fdata = response.data;
          console.log("Floor------>", this.fdata);
          if (this.fdata.length !== 0) {
            for (let i = 0; i < this.fdata.length; i++) {
              $("#fname").append(
                "<option value=" +
                this.fdata[i].id +
                ">" +
                this.fdata[i].name +
                "</option>"
              );
              $("#fname1").append(
                "<option value=" +
                this.fdata[i].id +
                ">" +
                this.fdata[i].name +
                "</option>"
              );
            }
          }
          else {
            $(".error-msg").text(
              "Please Upload Floormap to Register Master Gateway."
            );
          }
        } else {
          $(".error-msg").text("Unable to Fetch Floor Names");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          $("#displayModal").css("display", "block");
          // $("#content").text("User Session has Timed Out. Please Login Again.");
        } else {
          $(".error-msg").text(
            "Request Failed with status code (" + error.response.status + ")."
          );
        }
      });

    await this.getzone();
  }

  getzone = () => {
    let fid = $("#fname").val()
    axios({ method: 'GET', url: '/api/zones?floorid=' + fid })
      .then((res) => {
        console.log(res)
        let zdata = res.data;
        if (zdata.length !== 0) {
          for (let i = 0; i < zdata.length; i++) {
            $("#zname").append(
              "<option value=" +
              zdata[i].id +
              ">" +
              zdata[i].zonename +
              "</option>"
            );
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          $("#displayModal").css("display", "block");
          // $("#content").text("User Session has Timed Out. Please Login Again.");
        } else {
          $(".error-msg").text(
            "Request Failed with status code (" + error.response.status + ")."
          );
        }
      })
  }

  register = (e) => {
    e.preventDefault();
    $(".error-msg").text("")
    $(".success-msg").text("")
    $("#delete-form").css("display", "none");
    let data = {};
    if ($("#tagid").val().length === 0) {
      $(".error-msg").text("Please Enter Tag MAC ID.");
    } else if (
      !$("#tagid").val().match("([A-Za-z0-9]{2}[-]){5}([A-Za-z0-9]){2}")
    ) {
      $(".error-msg").text(
        "Invalid MAC ID Entered. Please Enter Proper MAC ID."
      );
    } else {
      data = {
        macaddress: $("#tagid").val(),
        x1: $("#x").val(),
        y1: $("#y").val(),
        x2: $("#x1").val(),
        y2: $("#y1").val(),
        floor_id: $("#fname").val(),
        contact: $("#tel").val(),
        mail: $("#email").val(),
        humi_low: $("#humid_low").val(),
        humi_high: $("#humid_high").val(),
        temp_low: $("#temp_low").val(),
        temp_high: $("#temp_high").val(),
        zone_id: $("#zname").val(),
      };
      console.log(data)
      if (
        data.x1 !== "" &&
        data.y1 !== "" &&
        data.x2 !== "" &&
        data.y2 !== "" &&
        data.floor_id !== "" &&
        data.contact !== "" &&
        data.mail !== "" &&
        data.humi_low !== "" &&
        data.humi_high !== "" &&
        data.temp_low !== "" &&
        data.temp_high !== "" &&
        data.zone_id !== ""
      ) {
        axios({
          method: "POST",
          url: tempertureSensor,
          data: data,
        })
          .then((response) => {
            console.log("======>", response);
            if (response.status === 201) {
              $(".success-msg").text(
                "Sensor is Registered Successfully."
              );
            } else {
              $(".error-msg").text(response.data.message);
            }
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 403) {
              $("#displayModal").css("display", "block");
              // $("#content").text(
              //   "User Session has Timed Out. Please Login Again."
              // );
            } else if (error.response.status === 400) {
              $(".error-msg").text("Tag is Already Registered.");
            } else if (error.response.status === 406) {
              $(".error-msg").text(error.response.data.message);
            } else {
              $(".error-msg").text(
                "Request Failed with status code (" +
                error.response.status +
                ")."
              );
            }
          });
      } else {
        $(".error-msg").text("Please Provide All Information.");
      }
    }
    $("input[type=text]").val("");
    $("input[type=email]").val("");
    $("input[type=number]").val("");
    $("input[type=tel]").val("");
  };

  unregister = (e) => {
    e.preventDefault();
    $(".error-msg").text("")
    $(".success-msg").text("")
    let id = $("#macid").val();
    if (id.length === 0)
      $(".error-msg").text("Please Enter MAC ID.");
    else if (!id.match("([A-Za-z0-9]{2}[-]){5}([A-Za-z0-9]){2}"))
      $(".error-msg").text("Invalid MAC ID Entered.");
    else {
      axios({
        method: "DELETE",
        url: tempertureSensor,
        data: { macaddress: id },
      })
        .then((response) => {
          if (response.status === 200) {
            $("#delete-form").css("display", "none");
            $(".success-msg").text("Sensor Removed Successfully.");
          } else {
            $(".error-msg").text(response.data.message);
          }
        })
        .catch((error) => {
          console.log("-------->", error);
          if (error.response.status === 403) {
            $("#displayModal").css("display", "block");
            // $("#content").text(
            //   "User Session has Timed Out. Please Login Again"
            // );
          } else if (error.response.status === 404) {
            $(".error-msg").text(error.response.data.message);
          } else {
            $(".error-msg").text(
              "Request Failed with status code (" + error.response.status + ")."
            );
          }
        });
    }
    $("input[type=text]").val("");
    $("input[type=email]").val("");
  };

  show = () => {
    document.getElementById("delete-form").style.display = $("#delete-form").css("display") === 'block' ? 'none' : 'block'
  }
  render() {
    return (
      <div style={{ marginLeft: '45px' }}>
        <h3 className='subheading' style={{ paddingTop: '1px' }}>Sensors Registration</h3>
        <div className="underline"></div>
        <div>
          <strong>
            <span className="error-msg" id="conf-error"></span>
          </strong>
          <strong>
            <span className="success-msg" id="conf-success"></span>
          </strong>
        </div>
        <form id="reg-form">
          <div className="input-group">
            <span className="label">Tag MAC ID :</span>
            <input
              type="text"
              id="tagid"
              required="required"
              placeholder="5a-c2-15-00-00-00"
            />
          </div>

          <div className="fading" style={{ display: "block" }}>
            <div style={{ display: 'flex' }}>
              <div>
                <div className="input-group">
                  <span className="label">Floor Name : </span>
                  <select name="type" id="fname" onChange={() => this.getzone()}></select>
                </div>
                <div className="input-group">
                  <span className="label">Zone Name : </span>
                  <select name="type" id="zname"></select>
                </div>
                <div className="input-group">
                  <span className="label">Temperature (in °C): </span>
                  <input type="number" id="temp_low" style={{ width: '100px' }} placeholder='min' required="required" />

                  <input type="number" id="temp_high" style={{ width: '100px', marginLeft: '30px' }} placeholder="max" required="required" />
                </div>
                <div className="input-group">
                  <span className="label">Humidity (in %RH) : </span>
                  <input type="number" id="humid_low" style={{ width: '100px' }} placeholder="min" required="required" />

                  <input type="number" id="humid_high" style={{ width: '100px', marginLeft: '30px' }} placeholder="max" required="required" />
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", margin: "15px", marginTop: '30px' }}>
            <input
              style={{ marginLeft: "180px", background: '#fe5b1bb3', color: '#000000b0' }}
              type="submit"
              onClick={() => {
                this.show();
              }}
              value="Remove Tag"
              className="btn success-btn"
            />
            <input style={{ marginLeft: "40px" }}
              type="submit"
              onClick={this.register}
              value="Register Tag"
              className="btn success-btn"
            />

          </div>
        </form>


        <form id="delete-form" className="fading" style={{ display: "none" }}>
          <div className="input-group">
            <span className="label">Tag MAC ID :</span>
            <input
              type="text"
              name="macid"
              id="macid"
              required="required"
              // onClick={this.hide}
              placeholder="5a-c2-15-00-00-00"
            />
          </div>

          <div className="input-group" style={{ marginLeft: "195px", marginTop: '20px' }}>
            <input
              style={{ background: '#fe5b1bb3', color: '#000000b0' }}
              type="submit"
              value="Delete Tag"
              onClick={this.unregister}
              className="btn warning-btn"
            />
          </div>
        </form>

        {/* SessionOut Component used here!  */}
        <SessionOut />
      </div>
    )
  }
}
