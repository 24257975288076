import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./sidebar.css";
import $ from "jquery";

const Background = {
    background: "linear-gradient(to right, rgb(255 158 121 / 48%), rgb(247 247 247))",
    color: '#737373',
    borderLeft: '1px solid white'
};

export function sidelinkClicked(id) {
    let element = document.getElementsByClassName("sideLink");
    for (let i = 0; i <= 6; i++) {
        element[i].style.background = "";
        element[i].firstChild.style.color = "";
        element[i].style.borderLeft = ""
        element[i].style.fontWeight = ""
        element[i].firstElementChild.nextElementSibling.style.color = "";
    }
    $(element[id]).css({
        "background": "linear-gradient(to right, rgb(255 158 121 / 48%), rgb(247 247 247))",
        "border-left": "3px solid rgb(254, 91, 27)",
        "font-weight": "700",
    })
    element[id].firstChild.style.color = "rgb(254, 91, 27)";
    element[id].firstElementChild.nextElementSibling.style.color = "rgb(254, 91, 27)";
}

export default class Sidebar extends Component {
    componentDidMount() {
        sidelinkClicked(0)
    }

    logout = () => {
        sessionStorage.removeItem('login')
        window.location.pathname = '/login'
    };

    render() {
        return (
            <div className='sidebar'>
                <Link to='/home' style={{ textDecoration: 'none' }}>
                    <img src="/images/Vacus_Logo.png" alt="logo"
                        className='vacus_logo'
                    />
                </Link>
                <Link to='/home' style={{ textDecoration: 'none' }}>
                    <span>
                        <div style={Background}
                            onClick={() => sidelinkClicked(0)}
                            className='sideLink'
                            id="option0"
                        >
                            <i className="fas fa-home"
                                style={{
                                    fontSize: '20px',
                                    marginRight: '10px',
                                    marginTop: "2px",
                                    color: '#737373'
                                }}>
                            </i>
                            <span style={{ fontSize: "17px", color: '#737373' }}>Home</span>
                        </div>
                    </span>
                </Link>

                <Link to='/uploadmap' style={{ textDecoration: 'none' }}>
                    <span>
                        <div style={Background}
                            onClick={() => sidelinkClicked(1)}
                            className='sideLink'
                            id="option2"
                        >
                            <i className="fas fa-cloud-upload">  </i>
                            <span>Upload Floor Map</span>
                        </div>
                    </span>
                </Link>

                <Link to='/zoneconfig' style={{ textDecoration: 'none' }}>
                    <span>
                        <div style={Background}
                            onClick={() => sidelinkClicked(2)}
                            className='sideLink'
                            id="option3"
                        >
                            <i className="fas fa-cogs"> </i>
                            <span>Zone Configuration</span>
                        </div>
                    </span>
                </Link>

                <Link to='/configuration' style={{ textDecoration: 'none' }}>
                    <span>
                        <div style={Background}
                            onClick={() => sidelinkClicked(3)}
                            className='sideLink'
                            id='option1'
                        >
                            <i className="fas fa-edit"
                                style={{
                                    fontSize: '20px',
                                    marginRight: '10px',
                                    marginTop: "2px",
                                    color: '#737373'
                                }}>
                            </i>
                            <span style={{ fontSize: "17px", color: '#737373' }}>Configuration</span>
                        </div>
                    </span>
                </Link>

                <Link to='/thermal' style={{ textDecoration: 'none' }}>
                    <span>
                        <div style={Background}
                            onClick={() => sidelinkClicked(4)}
                            className='sideLink'
                            id="option4"
                        >

                            <i className="fas fa-chart-bar"></i>
                            <span>Thermal Environment</span>
                        </div>
                    </span>
                </Link>
                <Link to='/health' style={{ textDecoration: 'none' }}>
                    <span>
                        <div style={Background}
                            onClick={() => sidelinkClicked(5)}
                            className='sideLink'
                            id="option5"
                        >
                            <i className="fas fa-desktop">
                            </i>
                            <span>System Health</span>
                        </div>
                    </span>
                </Link>
                <Link to='/alerts' style={{ textDecoration: 'none' }}>
                    <span>
                        <div style={Background}
                            onClick={() => sidelinkClicked(6)}
                            className='sideLink'
                            id="option5"
                        >
                            <i className="fad fa-exclamation-triangle"></i>
                            <span>Alerts</span>
                        </div>
                    </span>
                </Link>

                <Link to='/login' style={{ textDecoration: 'none' }}>
                    <span>
                        <div
                            onClick={this.logout}
                            className='sideLink logout' style={{ marginTop: '40px' }}
                            id="option6">
                            <i className="fas fa-power-off" style={{ color: "#fe7c49" }}></i>
                            <span style={{ color: "#fe7c49" }}>Logout</span>
                        </div>
                    </span>
                </Link>
            </div>
        )
    }
}


