import React, { Component } from 'react'
import { SessionOut, getPagination, TableDetails, DataLoading } from './common'
import './styles.css'
import $ from 'jquery';
import axios from 'axios';
import moment from "moment";
import { sidelinkClicked } from '../sidebar/Sidebar'

axios.defaults.xsrfHeaderName = "x-csrftoken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

export default class Alerts extends Component {

  constructor() {
    super();
    this.state = {
      loading: false,
      error: false,
      message: ''
    };
  }

  componentDidMount() {
    sidelinkClicked(6);
    $(".pagination").hide();
    $("#rangeDropdown").hide();
    this.getAlertData("first");
    this.interval = setInterval(() => {
      this.getAlertData("repeat");
    }, 15 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getAlertData = (callStatus) => {
    this.setState({ error: false, message: "" });
    if (callStatus === "first") {
      this.setState({ loading: true });
    } else {
      this.setState({ loading: false });
    }
    let alertType = $("#type").val();
    let type = alertType === "temp" ? "TEMPERATURE" : "HUMIDITY"
    axios({ method: "GET", url: "/api/alerts?value=" + alertType })
      .then((response) => {
        $(".pagination").hide();
        $("#rangeDropdown").hide();
        $("#table_det tbody").empty();
        $("#table_det thead").empty();
        let data = response.data;
        if (response.status === 200 || response.status === 201) {
          console.log("Alerts Response====>", response);
          if (data.length !== 0) {
            $("#table_det thead").append(
              "<tr>" +
              "<th>SNO</th>" +
              "<th>MAC ID</th>" +
              "<th>FLOOR NAME</th>" +
              "<th>ZONE NAME</th>" +
              "<th>BATTERY(%)</th>" +
              "<th>" + type + "</th>" +
              "<th>START TIME</th>" +
              "<th>END TIME</th>" +
              "<th>DURATION</th>" +
              "</tr>"
            );
            for (let i = 0; i < data.length; i++) {
              let start_time = data[i].timestamp.substring(0, 19).replace("T", " ");
              let end_time = data[i].lastseen.substring(0, 19).replace("T", " ");
              const startTime = moment(start_time);
              const endTime = moment(end_time);
              const dura = moment.duration(endTime.diff(startTime));
              const seconds = dura.asSeconds();
              var hoursLeft = Math.floor(seconds / 3600);
              var min = Math.floor((seconds - hoursLeft * 3600) / 60);
              var secondsLeft = seconds - hoursLeft * 3600 - min * 60;
              secondsLeft = Math.round(secondsLeft * 100) / 100;
              let duration = hoursLeft < 10 ? "0" + hoursLeft : hoursLeft;
              duration += ":" + (min < 10 ? "0" + min : min);
              duration += ":" + (secondsLeft < 10 ? "0" + secondsLeft : secondsLeft);

              $("#table_det tbody").append(
                "<tr>" +
                "<td>" + (i + 1) + "</td>" +
                "<td>" + data[i].sensor.macid + "</td>" +
                "<td>" + data[i].floor.name + "</td>" +
                "<td>" + data[i].sensor.zone.zonename + "</td>" +
                "<td>" + data[i].battery + "</td>" +
                "<td>" + data[i].temp_humi + "</td>" +
                "<td>" + start_time + "</td>" +
                "<td>" + end_time + "</td>" +
                "<td>" + duration + "</td>" +
                "</tr>"
              );
            }
            if (data.length > 25) {
              $(".pagination").show();
              $("#rangeDropdown").show();
              getPagination(this, "#table_det");
            }
            this.setState({ loading: false });
          }
          else {
            this.setState({ error: true, message: 'Alert Data Not Found' });
            this.setState({ loading: false });
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response.status === 403) {
          $("#displayModal").css("display", "block");
        } else if (error.response.status === 404) {
          $(".pagination").hide();
          $("#rangeDropdown").hide();
          $("#table_det tbody").empty();
          $("#table_det thead").empty();
          this.setState({ error: true, message: 'Alert Data Not Found' })
        } else {
          this.setState({ error: true, message: 'Request Failed' })
        }
      });
  };


  render() {
    const { loading, error, message } = this.state;
    return (
      <div className='maindiv'>
        <div className="panel">
          <h1>Alerts</h1>
          <div className='underline'></div>
          <div className="container fading" style={{ marginTop: "50px" }}>
            <div className="row">
              <div className="input-group">
                <span className="label">Alert Type : </span>
                <select name="type" id="type" onChange={() => this.getAlertData("first")}>
                  <option value="temp">Temperature</option>
                  <option value="hum">Humidity</option>
                </select>
              </div>
            </div>

            <br></br>
            {error && (
              <div
                style={{ color: "red" }}>
                <strong>{message}</strong>
              </div>
            )}
            <TableDetails />
          </div>

          {loading === true && (
            <div
              style={{
                top: "0%",
                left: "0%",
              }} className="frame">
              <DataLoading />
            </div>
          )}
        </div>

        {/* SessionOut Component used here!  */}
        <SessionOut />
      </div>
    )
  }
}
