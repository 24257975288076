import React, { Component } from 'react'
import { getPagination, TableDetails, DataLoading, SessionOut } from './common'
import './styles.css'
import $ from 'jquery';
import axios from 'axios';
import {
   masterGateway,
   slaveGateway,
   tempertureSensor,
} from "../../urls/apis";
import { sidelinkClicked } from "../sidebar/Sidebar";

export default class Health extends Component {
   constructor() {
      super();
      this.state = {
         message: "",
         error: false,
         loading: false,
      };
   }
   componentDidMount() {
      sidelinkClicked(5)
      $(".pagination").hide();
      $("#rangeDropdown").hide();
      this.getTableDetails("first");
      this.interval = setInterval(() => {
         this.getTableDetails("repeat");
      }, 15 * 1000);
   }

   componentWillUnmount() {
      clearInterval(this.interval);
   }
   getTableDetails = (callStatus) => {
      this.setState({ error: false, message: "" });
      if (callStatus === "first") {
         this.setState({ loading: true });
      } else {
         this.setState({ loading: false });
      }
      if ($("#healthtype").val() === "Master") {
         axios({ method: "GET", url: masterGateway })
            .then((response) => {
               const data = response.data;
               console.log("Master Response====>", data);
               $(".pagination").hide();
               $("#rangeDropdown").hide();
               $("#table_det tbody").empty();
               $("#table_det thead").empty();
               if (data.length !== 0 && response.status === 200) {
                  $("#table_det thead").append(
                     "<tr>" +
                     "<th>SNO</th>" +
                     "<th>MASTER ID</th>" +
                     "<th>FLOOR NAME</th>" +
                     "<th>LAST SEEN</th>" +
                     " <th>STATUS</th>" +
                     "</tr>"
                  );
                  for (let i = 0; i < data.length; i++) {
                     let status = 'red';
                     if ((new Date() - new Date(data[i].lastseen)) <= (2 * 60 * 1000)) {
                        status = "green";
                     }
                     $("#table_det tbody").append(
                        "<tr class=row_" + (i + 1) + ">" +
                        "<td>" + (i + 1) + "</td>" +
                        "<td>" + data[i].gatewayid + "</td>" +
                        "<td>" + data[i].floor.name + "</td>" +
                        "<td>" + data[i].lastseen.replace("T", " ").substr(0, 19) + "</td>" +
                        "<td><div class='circle' style='margin:auto;background-color:" +
                        status +
                        ";'></div></td> " +
                        "</tr>"
                     )
                  }
                  if (data.length > 25) {
                     $(".pagination").show();
                     $("#rangeDropdown").show();
                     getPagination(this, "#table_det");
                  }
                  this.setState({ loading: false });
               } else {
                  this.setState({ message: "No Master Data Found!", error: true, loading: false });
               }
            })

            .catch((error) => {
               console.log("ERROR====>", error);
               this.setState({ loading: false });
               if (error.response.status === 403) {
                  $("#displayModal").css("display", "block");
               } else if (error.response.status === 404) {
                  this.setState({ error: true, message: 'No Master Data Found' })
                  $("#table_det thead").empty();
                  $("#table_det tbody").empty();
               }
            })
      }
      else if ($("#healthtype").val() === "Slave") {
         axios({ method: "GET", url: slaveGateway })
            .then((response) => {
               const data = response.data;
               console.log('=====>slave', data);
               $(".pagination").hide();
               $("#rangeDropdown").hide();
               $("#table_det tbody").empty();
               $("#table_det thead").empty();
               if (data.length !== 0 && response.status === 200) {
                  $("#table_det thead").append(
                     "<tr>" +
                     "<th>SNO</th>" +
                     "<th>SLAVE ID</th>" +
                     "<th>MASTER ID</th>" +
                     "<th>FLOOR NAME</th>" +
                     "<th>LAST SEEN</th>" +
                     " <th>STATUS</th>" +
                     "</tr>"
                  );
                  for (let i = 0; i < data.length; i++) {
                     let status = 'red';
                     if ((new Date() - new Date(data[i].lastseen)) <= (2 * 60 * 1000)) {
                        status = "green";
                     }
                     $("#table_det tbody").append(
                        "<tr class=row_" + (i + 1) + ">" +
                        "<td>" + (i + 1) + "</td>" +
                        "<td>" + data[i].gatewayid + "</td>" +
                        "<td>" + data[i].master.gatewayid + "</td>" +
                        "<td>" + data[i].master.floor.name + "</td>" +
                        "<td>" + data[i].lastseen.replace("T", " ").substr(0, 19) + "</td>" +
                        "<td><div class='circle' style='margin:auto;background-color:" +
                        status +
                        ";'></div></td> " +
                        "</tr>"
                     )
                  }
                  if (data.length > 25) {
                     $(".pagination").show();
                     $("#rangeDropdown").show();
                     getPagination(this, "#table_det");
                  }
                  this.setState({ loading: false });
               } else {
                  this.setState({ message: "No Slave Data Found!", error: true, loading: false });
               }
            })
            .catch((error) => {
               console.log('Health Slave Gate Error====', error);
               this.setState({ loading: false });
               if (error.response.status === 403) {
                  $("#displayModal").css("display", "block");
               } else if (error.response.status === 404) {
                  this.setState({ error: true, message: 'No Slave Data Found' })
                  $("#table_det thead").empty();
                  $("#table_det tbody").empty();
               }
            })
      }

      else if ($("#healthtype").val() === 'Sensors') {
         axios({ method: "GET", url: tempertureSensor + "?floorid=0" })
            .then((response) => {
               const data = response.data;
               console.log('=====>', response);
               $(".pagination").hide();
               $("#rangeDropdown").hide();
               $("#table_det tbody").empty();
               $("#table_det thead").empty();
               if (data.length !== 0 && response.status === 200) {
                  $("#table_det thead").append(
                     "<tr>" +
                     "<th>SNO</th>" +
                     "<th>MAC ID </th>" +
                     "<th>FLOOR NAME</th>" +
                     "<th>ZONE NAME</th>" +
                     "<th>BATTERY(%)</th>" +
                     "<th>LAST SEEN</th>" +
                     " <th>STATUS</th>" +
                     "</tr>"
                  );
                  for (let i = 0; i < data.length; i++) {
                     let status = 'red';
                     if ((new Date() - new Date(data[i].lastseen)) <= (2 * 60 * 1000)) {
                        status = "green";
                     }
                     $("#table_det tbody").append(
                        "<tr class=row_" + (i + 1) + ">" +
                        "<td>" + (i + 1) + "</td>" +
                        "<td>" + data[i].macid + "</td>" +
                        "<td>" + data[i].floor.name + "</td>" +
                        "<td>" + data[i].zone.zonename + "</td>" +
                        "<td>" + data[i].battery + "</td>" +
                        "<td>" + data[i].lastseen.replace("T", " ").substr(0, 19) + "</td>" +
                        "<td><div class='circle' style='margin:auto;background-color:" +
                        status +
                        ";'></div></td> " +
                        "</tr>"
                     )
                  }
                  if (data.length > 25) {
                     $(".pagination").show();
                     $("#rangeDropdown").show();
                     getPagination(this, "#table_det");
                  }
                  this.setState({ loading: false });
               } else {
                  this.setState({ message: "No Sensors Data Found!", error: true, loading: false });
               }
            })
            .catch((error) => {
               console.log('Health Sensors Tag Gate Error====', error);
               this.setState({ loading: false });
               if (error.response.status === 403) {
                  $("#displayModal").css("display", "block");
               } else if (error.response.status === 404) {
                  this.setState({ error: true, message: 'No Sensor Data Found' })
                  $("#table_det thead").empty();
                  $("#table_det tbody").empty();
               }
            })
      }

   };

   render() {
      const { loading, error, message } = this.state;
      return (
         <div className='maindiv'>
            <div className="panel">
               <h1>System Health</h1>
               <div className='underline'>   </div>
               <div style={{ marginBottom: '30px' }}>
                  <div className="inputdiv" style={{ marginTop: "20px" }}>
                     <span className="label">Health:</span>
                     <select
                        name="healthtype"
                        id="healthtype"
                        required="required"
                        onChange={() => {
                           clearInterval(this.interval)
                           this.componentDidMount()
                        }}>
                        <option>Master</option>
                        <option>Slave</option>
                        <option>Sensors</option>

                     </select>
                  </div>
               </div>
               {error && (
                  <div style={{ color: "red", marginTop: "20px" }}>
                     <strong>{message}</strong>
                  </div>
               )}
               <TableDetails />

               {loading === true && (
                  <div
                     style={{
                        top: "0%",
                        left: "0%",
                     }} className="frame">
                     <DataLoading />
                  </div>
               )}
            </div>
            {/* SessionOut Component used here!  */}
            <SessionOut />
         </div>
      )
   }
}
