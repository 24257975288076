import React, { Component } from 'react'
import axios from "axios";
import "./styles.css";
import $ from "jquery";
import { masterGateway, floorMap } from "../../urls/apis";
import { SessionOut } from './common';


export default class Master extends Component {
  componentDidMount() {
    axios({
      method: "GET",
      url: floorMap,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.fdata = response.data;
          if (this.fdata.length !== 0) {
            for (let i = 0; i < this.fdata.length; i++) {
              $("#fname").append("<option>" + this.fdata[i].name + "</option>");
            }
          } else {
            $(".error-msg").text(
              "Please Upload Floor Map to Register Master Gateway."
            );
          }
        } else {
          $(".error-msg").text("Unable to Fetch Floor Names");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          $("#displayModal").css("display", "block");
          // $("#content").text("User Session has Timed Out. Please Login Again.");
        } else if (error.response.status === 404) {
          $(".error-msg").text("No Floor Map is Registered.");
        } else {
          $(".error-msg").text(
            "Request Failed with status code (" + error.response.status + ")."
          );
        }
      });
  }


  /** Hides all error and success messages displayed on all button clicks */
  hide = () => {
    document.getElementById("master-error").innerHTML = "";
    document.getElementById("master-success").innerHTML = "";
  };

  /** Reigster the master gateway for particular floor */
  registerGateway = (e) => {
    e.preventDefault();
    this.hide();
    let id = document.getElementById("gatewayid").value;
    if (id.length === 0) {
      document.getElementById("master-error").innerHTML =
        "Please Enter Gateway ID.";
    } else if (!id.match("([A-Za-z0-9]{2}[-]){5}([A-Za-z0-9]){2}")) {
      document.getElementById("master-error").innerHTML =
        "Invalid Gateway ID Entered.";
    } else {
      document.getElementById("master-error").innerHTML = "";
      let name = document.getElementById("fname").value;
      let floorid;
      for (let i = 0; i < this.fdata.length; i++) {
        if (this.fdata[i].name === name) {
          floorid = this.fdata[i].id;
          break;
        }
      }
      axios({
        method: "POST",
        url: masterGateway,
        data: { macaddress: id, id: floorid },
      })
        .then((response) => {
          console.log("Master Res ====>", response);
          if (response.status === 201 || response.status === 200) {
            document.getElementById("master-success").innerHTML =
              "Gateway Registered Successfully.";
            $("#gatewayid").val("");
          } else if (response.status === 208) {
            document.getElementById("master-error").innerHTML = response.data.message;
          } else {
            document.getElementById("master-error").innerHTML =
              "Unable to Register Gateway.";
          }
        })
        .catch((error) => {
          console.log("Master Error ====>", error);
          if (error.response.status === 403) {
            $("#displayModal").css("display", "block");
          } else {
            document.getElementById("master-error").innerHTML =
              "Request Failed with status code (" +
              error.response.status +
              ").";
          }
        });
    }
  };

  /** Remove master gateway for particular floor along with all slaves under it */
  unregisterGateway = (e) => {
    this.hide();
    e.preventDefault();
    let id = document.getElementById("gateway").value;
    if (id.length === 0) {
      document.getElementById("master-error").innerHTML =
        "Please Enter Gateway ID.";
    } else if (!id.match("([A-Za-z0-9]{2}[-]){5}([A-Za-z0-9]){2}")) {
      document.getElementById("master-error").innerHTML =
        "Invalid Gateway ID Entered.";
    } else {
      axios({
        method: "DELETE",
        url: masterGateway,
        data: { macaddress: id },
      })
        .then((response) => {
          console.log("Master Res ====>", response);
          if (response.status === 201 || response.status === 200) {
            document.getElementById("master-success").innerHTML =
              "Gateway removed Successfully.";
            $("#gateway").val("");
            this.show();
          } else {
            document.getElementById("master-error").innerHTML =
              "Gateway removed Successfully.";
          }
        })
        .catch((error) => {
          console.log("Master Error ====>", error);
          if (error.response.status === 403) {
            $("#displayModal").css("display", "block");
          } else if (error.response.status === 404) {
            document.getElementById("master-error").innerHTML = error.response.data.message;
          } else {
            document.getElementById("master-error").innerHTML =
              "Request Failed with status code (" +
              error.response.status +
              ").";
          }
        });
    }

  };
  show = () => {
    document.getElementById("gateway-del-form").style.display = $("#gateway-del-form").css("display") === 'block' ? 'none' : 'block'
  }

  // sessionTimeout = () => {
  //   $("#displayModal").css("display", "none");
  //   sessionStorage.removeItem('login')
  //   window.location.pathname = '/login'
  // };
  render() {
    return (
      <div style={{ marginLeft: '45px' }}>
        <h3 className='subheading' style={{ paddingTop: '1px' }}>Master Registration</h3>
        <div className="underline"></div>

        <div>
          <strong>
            <span className="error-msg" id="master-error"></span>
          </strong>
          <strong>
            <span className="success-msg" id="master-success"></span>
          </strong>
        </div>
        {/* Form to register master gateway */}
        <form id="gateway-reg-form">
          {/* Select list for Floor names */}
          <div className="input-group">
            <span className="label">Floor Name :</span>
            <select name="type" id="fname"></select>
          </div>
          {/* Input field for Gateway ID */}
          <div className="input-group">
            <span className="label">Gateway ID :</span>
            <input
              type="text"
              name="gatewayid"
              id="gatewayid"
              required="required"
              placeholder="5a-c2-15-00-00-00"
              onChange={this.hide}
            />
          </div>
        </form>
        <div style={{ display: "flex", margin: "15px", marginLeft: '140px', marginTop: '20px' }}>
          <input
            style={{ background: '#fe5b1bb3', color: '#000000b0' }}
            type="submit"
            onClick={() => {
              this.show();
            }}
            value="Remove Gateway"
            className="btn success-btn"
          />
          <input style={{ marginLeft: "40px", }}
            type="submit"
            onClick={this.registerGateway}
            value="Register Gateway"
            className="btn success-btn"
          />
        </div>

        <form
          id="gateway-del-form"
          className="fading"
          style={{ display: "none" }}
        >
          {/* Input Field for Tag MAC ID */}
          <div className="input-group">
            <span className="label">Gateway MAC ID :</span>
            <input
              type="text"
              name="gateway"
              id="gateway"
              required="required"
              onClick={this.hide}
              placeholder="5a-c2-15-00-00-00"
            />
          </div>

          <div className="input-group" style={{ margin: "15px", marginLeft: '200px' }}>
            <input
              style={{ background: '#fe5b1bb3', color: '#000000b0' }}
              type="submit"
              value="Delete Gateway"
              onClick={this.unregisterGateway}
              className="btn success-btn"
            />
          </div>
        </form>


        {/* SessionOut Component used here!  */}
        <SessionOut />
      </div>
    )
  }
}
